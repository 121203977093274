'use client';

import React, { useMemo, useEffect } from 'react';
import Avatar from 'react-avatar';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GetRequest } from '@qb/httpRequest/Request';
import { getWebApiPrefixForTenant } from '@qb/httpRequest/utils';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { addCompanyLogoImage } from '../../store/reducers/main';
import { Colors } from './UserImageIcon';
const companyPhotoIconWidthHeight = 48;
// Cache
const MADE_REQUEST_FOR_COMPANY_ID = {};
export default function CompanyImageIcon(props) {
  const {
    companyLogoImages
  } = useSelector(({
    main
  }) => main);
  const dispatch = useDispatch();
  const shouldFetch = useMemo(() => {
    return !props.url && props.company.id;
  }, [props.url, props.company.id]);
  const tenantAlias = useTenantAlias();
  useEffect(() => {
    if (shouldFetch) {
      if (companyLogoImages[props.company.id]) return;
      if (MADE_REQUEST_FOR_COMPANY_ID[props.company.id]) return;
      MADE_REQUEST_FOR_COMPANY_ID[props.company.id] = true;
      GetRequest(`${getWebApiPrefixForTenant(tenantAlias)}/company/${props.company.id}/logo`, data => {
        dispatch(addCompanyLogoImage({
          id: props.company.id,
          url: data.url
        }));
      });
    }
  }, [shouldFetch, companyLogoImages, dispatch, props.company.logoURL, tenantAlias, props.company.id]);
  if (props.url) {
    return <img src={props.url} title={props.company.name} style={{
      maxHeight: props.overrideHeight || companyPhotoIconWidthHeight,
      maxWidth: props.overrideWidth || companyPhotoIconWidthHeight,
      height: props.overrideHeight || companyPhotoIconWidthHeight,
      width: props.overrideWidth || companyPhotoIconWidthHeight
    }} alt={props.company.name} />;
  } else if (shouldFetch && !MADE_REQUEST_FOR_COMPANY_ID[props.company.id] && !companyLogoImages[props.company.id]) {
    return <Skeleton width={props.overrideWidth || companyPhotoIconWidthHeight} height={props.overrideHeight || companyPhotoIconWidthHeight} />;
  } else if (shouldFetch && companyLogoImages[props.company.id]) {
    return <img src={props.company.logoURL || companyLogoImages[props.company.id]} title={props.company.name} style={{
      maxHeight: props.overrideHeight || companyPhotoIconWidthHeight,
      maxWidth: props.overrideWidth || companyPhotoIconWidthHeight,
      height: props.overrideHeight || companyPhotoIconWidthHeight,
      width: props.overrideWidth || companyPhotoIconWidthHeight
    }} alt={props.company.name} />;
  } else {
    let color = Avatar.getRandomColor(props.company.name.charAt(0), Colors);
    return <Avatar title={props.company.name} name={props.company.name.charAt(0)} size={props.overrideWidth || companyPhotoIconWidthHeight} textSizeRatio={2} color={color} />;
  }
}
CompanyImageIcon.propTypes = {
  company: PropTypes.object.isRequired,
  overrideWidth: PropTypes.number,
  overrideHeight: PropTypes.number,
  overrideFontSize: PropTypes.number,
  style: PropTypes.object,
  url: PropTypes.string
};